import axios from 'axios'
import { DiscogsRelease, DiscogsSearchResults, DownloadLinksResponse, PlaylistResponse, YoutubeResponse } from './types'

export const DadAPI = {
  extractDownloadLinksByUrl: async function(lib: string /* loc or ucsb */, url: string): Promise<DownloadLinksResponse> {
    let results = {} as DownloadLinksResponse

    await axios({
      method: 'get',
      baseURL: 'https://plxbahnb4h.execute-api.ap-southeast-2.amazonaws.com',
      // baseURL: 'http://localhost:5000',
      url: '/ExtractDownloadLinks/'+lib,
      params: {
        url
      },
    })
    .then(response => {
      results = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
      throw error
    })

    return results 
  },
  extractDownloadLinksByMatrix: async function(label: string /* loc or ucsb */, matrixNo: string): Promise<DownloadLinksResponse> {
    let results = {} as DownloadLinksResponse

    await axios({
      method: 'get',
      baseURL: 'https://plxbahnb4h.execute-api.ap-southeast-2.amazonaws.com',
      // baseURL: 'http://localhost:5000',
      url: '/ExtractDownloadLinks/matrix',
      params: {
        label,
        matrixNo
      },
    })
    .then(response => {
      results = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
      throw error
    })

    return results 
  },
}

export const DiscogsAPI = {
  searchFor: async function(artistAndSong: string) {
    let results = ''

    await axios({
      method: 'get',
      baseURL: 'https://api.discogs.com',
      url: '/database/search',
      params: {
        q: artistAndSong
      },
      headers: {
        'Authorization': 'Discogs token=vMWwOyiWaqYMiCwlGIYOHlIrWrFtLelQngtwhqik'
      }
    })
    .then(response => {
      results = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
    })

    return results
  },
  searchForArtist: async function(artist: string, page?: number): Promise<DiscogsSearchResults> {
    let results = {}  as DiscogsSearchResults

    await axios({
      method: 'get',
      baseURL: 'https://api.discogs.com',
      url: '/database/search',
      params: {
        q: '',
        artist: artist,
        format: 'Shellac',
        per_page: 100,
        page: page || 1
      },
      headers: {
        'Authorization': 'Discogs token=vMWwOyiWaqYMiCwlGIYOHlIrWrFtLelQngtwhqik'
      }
    })
    .then(response => {
      results = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
    })

    return results
  },
  searchForLabel: async function(label: string, page?: number): Promise<DiscogsSearchResults> {
    let results = {}  as DiscogsSearchResults

    await axios({
      method: 'get',
      baseURL: 'https://api.discogs.com',
      url: '/database/search',
      params: {
        q: '',
        label: label,
        format: 'Shellac',
        per_page: 100,
        page: page || 1
      },
      headers: {
        'Authorization': 'Discogs token=vMWwOyiWaqYMiCwlGIYOHlIrWrFtLelQngtwhqik'
      }
    })
    .then(response => {
      results = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
    })

    return results
  },
  searchForSpecific: async function(artist: string, song: string): Promise<DiscogsSearchResults> {
    let results = {} as DiscogsSearchResults

    await axios({
      method: 'get',
      baseURL: 'https://api.discogs.com',
      url: '/database/search',
      params: {
        q: '',
        artist: artist,
        release_title: song
      },
      headers: {
        'Authorization': 'Discogs token=vMWwOyiWaqYMiCwlGIYOHlIrWrFtLelQngtwhqik'
      }
    })
    .then(response => {
      results = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
    })

    return results
  },
  getRelease: async function(id: number): Promise<DiscogsRelease> {
    let release = {} as DiscogsRelease

    await axios({
      method: 'get',
      baseURL: 'https://api.discogs.com',
      url: `/releases/${id}`,
      headers: {
        'Authorization': 'Discogs token=vMWwOyiWaqYMiCwlGIYOHlIrWrFtLelQngtwhqik'
      }
    })
    .then(response => {
      release = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
      return undefined
    })

    return release as DiscogsRelease
  },
  getResource: async function(url: string): Promise<unknown> {
    let release = {} as unknown

    if (url.startsWith('https://api.discogs.com')) url = url.substring(23)

    await axios({
      method: 'get',
      baseURL: 'https://api.discogs.com',
      url,
      headers: {
        'Authorization': 'Discogs token=vMWwOyiWaqYMiCwlGIYOHlIrWrFtLelQngtwhqik'
      }
    })
    .then(response => {
      release = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
      return undefined
    })

    return release
  }
}

const YT_API_KEYS = [ 
  "AIzaSyCp6cviG-P6R6hJvMjzGhXF0-Zgy473Pps", 
  "AIzaSyBJfYHhuYAh5fczHgc4lNz26ov1-k7eUkI", 
  "AIzaSyBL5ZgzMJ7Ni9sfjZHfMx9ljbaEaAq0AdY", 
  "AIzaSyD9lTBc1WI8AFZaNHZ7NfgY9i2yP0EUg6E",
  "AIzaSyBwsxGfITgGsg333s6SZgGDY78PVlmwdzs",
  "AIzaSyC-B66wQURLf_agmpfjMabE_NotHSJRISg"
];

export const YoutubeAPI = {
  searchFor: async function(keyword: string, pageToken?: string): Promise<YoutubeResponse> {
    let ytResponse = {} as YoutubeResponse

    let randIndex = Math.floor(Math.random() * YT_API_KEYS.length)
    let key = YT_API_KEYS[randIndex]

    let url = `youtube/v3/search?maxResults=50&q=${encodeURI(keyword)}&type=video&videoDuration=any&part=snippet&key=${key}`

    if (pageToken) {
      url += `&pageToken=${pageToken}`
    }

    await axios({
      method: 'get',
      baseURL: 'https://youtube.googleapis.com',
      url
    })
    .then(response => {
      ytResponse = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
      return undefined
    })

    return ytResponse
  },
  getPlaylistItems: async function(playlistId: string, pageToken?: string): Promise<PlaylistResponse> {
    let ytResponse = {} as PlaylistResponse

    let randIndex = Math.floor(Math.random() * YT_API_KEYS.length)
    let key = YT_API_KEYS[randIndex]

    let url = `youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=50&playlistId=${playlistId}&key=${key}`

    if (pageToken) {
      url += `&pageToken=${pageToken}`
    }

    await axios({
      method: 'get',
      baseURL: 'https://youtube.googleapis.com',
      url
    })
    .then(response => {
      ytResponse = response.data
    })
    .catch(error => {
      console.warn('error with api', error)
      return undefined
    })

    return ytResponse
  }
}
